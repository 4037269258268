import { flex } from "config/mixins"
import styled from "styled-components"
import { font, mediaQueries } from "Styles/styleGlobal"

export const BankIDLink = styled.div`
  text-align: center;
  font-weight: bold;
  margin: 2.5em 0;
  a {
    color: ${(p) => p.theme.fontColor};
    text-decoration: underline;
    font-weight: 400;
    font-size: ${font.size.sm};
    &:hover {
      text-decoration: none;
    }
  }
  @media screen and (min-width: ${mediaQueries.medium}) {
    a {
      font-size: ${font.size.base};
    }
  }
`

export const InputWrap = styled.div`
  position: relative;
  margin: 1.5em 0 2em;

  svg {
    position: absolute;
    z-index: 9;
    transform: scale(1.3) translate(0, -50%);
    right: 30px;
    top: 53%;
  }
  label {
    top: 50%;
    transform: scale(1) translate(0, -50%);
  }
  label + div {
    position: absolute;
    top: calc(60px + 1em);
  }
`

export const LeftSection = styled.div`
  ${flex.column}
  flex: 1;
  padding: 0 1em;

  @media screen and (min-width: ${mediaQueries.medium}) {
    padding: 0 2em;
  }
`

export const RightSection = styled.div`
  display: none;
  @media screen and (min-width: ${mediaQueries.medium}) {
    ${flex.center}
    background-color: #fef7e6;
    padding: 0 1em;
  }
`

export const LoginWrap = styled.div`
  ${flex.column}
  height: 100vh;

  @media screen and (min-width: ${mediaQueries.medium}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
  }
`

export const LogoWrap = styled.div`
  ${flex.alignCenter}

  svg path {
    fill: ${(p) => p.theme.fontColor};
  }
`

export const HeaderBlock = styled.header`
  ${flex.alignCenterBetween}
  margin: 0 auto;
  padding: 2em 0 1em;
  width: 100%;

  @media screen and (min-width: ${mediaQueries.medium}) {
    max-width: 400px;
  }
`
