import type { ChangeEvent, FC } from "react"
import { FormattedMessage } from "react-intl"
import { SubmitHandler, useForm, FormProvider } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

import PEP from "Components/KYC/PepUpdated/PEPupdated"
import Button from "UI/Button"
import Toggle from "UI/Toggle"
import { RadioGroupField } from "Components/form/RadioGroupField"
import { TextField } from "Components/form/TextField"
import { SelectField } from "Components/form/SelectField"
import { filterPepFields } from "Utils/kycUtils"
import { AMLQuestionsSchema } from "./validation"
import { livingInSweedenOptions, useOccupancyOptions } from "./options"
import { initialData, emptyPepData } from "./initialData"
import type {
  AMLQuestionsPropTypes,
  IAMLQuestionsRequest,
} from "./AMLQuestions.types"
import * as S from "./AMLQuestions.styles"

const AMLQuestions: FC<AMLQuestionsPropTypes> = ({
  handleAcceptOffer,
  isSubmitting,
}) => {
  const occupancyOptions = useOccupancyOptions()

  const form = useForm<IAMLQuestionsRequest>({
    values: initialData,
    resolver: yupResolver(AMLQuestionsSchema),
  })

  const onSubmit: SubmitHandler<IAMLQuestionsRequest> = (values) => {
    const filteredPepFields =
      values?.pep && (filterPepFields(values) as IAMLQuestionsRequest)

    const data = {
      ...values,
      pep: filteredPepFields || null,
    } as IAMLQuestionsRequest

    handleAcceptOffer(data)
  }

  const handleTogglePep = () => {
    form.setValue("pep", form.getValues().pep === null ? emptyPepData : null)
  }

  const handleRadioButtonChange = (event: ChangeEvent<HTMLInputElement>) =>
    form.setValue("living_in_sweden", event.target.value === "true")

  const livingInSweeden = form.watch("living_in_sweden")
  const pep = form.watch("pep")

  return (
    <S.AMLQuestionsWrap>
      <S.AMLQuestionsDescription>
        <FormattedMessage
          id="app.onboarding.accept.AMLDescription"
          defaultMessage="Innan du kan gå vidare behöver vi ställa några kompletterande frågor."
        />
      </S.AMLQuestionsDescription>
      <FormProvider {...form}>
        <S.AMLQuestionsForm onSubmit={form.handleSubmit(onSubmit)}>
          <S.RadioLabel>
            <FormattedMessage
              id="app.onboarding.accept.AMLQuestions.livingInSweden.title"
              defaultMessage="Är du bosatt i och enbart skattskyldig till Sverige?"
            />
          </S.RadioLabel>
          <S.RadioWrap>
            <RadioGroupField
              transformValueToString={String}
              control={form.control}
              name="living_in_sweden"
              options={livingInSweedenOptions}
              rules={{
                onChange: handleRadioButtonChange,
              }}
            />
          </S.RadioWrap>

          {!livingInSweeden && (
            <S.InputContainer>
              <TextField
                control={form.control}
                name="birthplace_citizenship"
                placeholder={
                  <FormattedMessage
                    id="app.onboarding.accept.AMLQuestions.input.birthplaceCitizenship"
                    defaultMessage="Födelseort och medborgarskap"
                  />
                }
              />
              <SelectField
                control={form.control}
                name="occupancy"
                placeholderOption={{
                  name: "Land där du bor nu",
                  value: "",
                  id: 0,
                }}
                options={occupancyOptions}
              />
              <TextField
                control={form.control}
                name="tax_domicile"
                placeholder={
                  <FormattedMessage
                    id="app.onboarding.accept.AMLQuestions.input.taxDomicile"
                    defaultMessage="Skatterättslig hemvist (om annan än Sverige)"
                  />
                }
              />
              <TextField
                control={form.control}
                name="tax_identification_number"
                placeholder={
                  <FormattedMessage
                    id="app.onboarding.accept.AMLQuestions.input.taxIdentificationNumber"
                    defaultMessage="Utländskt skatteregistreringsnummer"
                  />
                }
              />
            </S.InputContainer>
          )}
          <S.ToggleWrap>
            <S.ToggleLabel>
              <FormattedMessage
                id="app.mypages.investments.onboarding.KYC.private.PEP.title"
                defaultMessage="Är du en PEP (Person i politiskt utsatt ställning), närstående - eller har/haft ett affärsförhållande till en person som är eller har varit PEP?"
              />
              <span>
                <FormattedMessage id="app.common.no" defaultMessage="Nej" />
                <Toggle checked={!!pep} onChange={handleTogglePep} />
                <FormattedMessage id="app.common.yes" defaultMessage="Ja" />
              </span>
            </S.ToggleLabel>
          </S.ToggleWrap>
          {pep && <PEP form={form} />}
          <S.ButtonWrap>
            <Button
              type="submit"
              isLoading={isSubmitting}
              disabled={isSubmitting}
            >
              <FormattedMessage
                id="app.onboarding.accept.nextCTAText"
                defaultMessage="Gå vidare"
              />
            </Button>
          </S.ButtonWrap>
        </S.AMLQuestionsForm>
      </FormProvider>
    </S.AMLQuestionsWrap>
  )
}

export default AMLQuestions
